import axios from "axios";
import "./App.css";
import { useRef, useState } from "react";
import clsx from "clsx";

function App() {
  const inputFile = useRef(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const downloadTemplate = async () => {
    const response = await axios({
      url: "https://kaka-giovanny.louisaldorio.com/feng-tiao/template",
      method: "GET",
      responseType: "blob",
    });

    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "template.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const onFileChanged = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    setIsUploadLoading(true);

    try {
      if (event.target.files.length > 0) {
        var file = event.target.files[0];

        var formData = new FormData();
        formData.append("excel_file", file);

        await axios
          .post(
            "https://kaka-giovanny.louisaldorio.com/feng-tiao/generate",
            formData,
            {
              responseType: "blob",
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            const href = URL.createObjectURL(response.data);

            let tab = window.open(href, "_blank");
            if (tab) {
              tab.focus();
            }
          });
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsUploadLoading(false);
      event.target.value = null;
    }
  };

  return (
    <div id="main">
      <div className="center-box">
        <button
          id="download-template"
          className="btn btn-secondary"
          onClick={downloadTemplate}
        >
          Download Excel Template
        </button>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={onFileChanged}
        />
        <button
          id="upload-file"
          disabled={isUploadLoading}
          className={clsx({
            "btn btn-primary": true,
            "btn-loading": isUploadLoading,
          })}
          onClick={() => {
            inputFile.current.click();
          }}
        >
          {isUploadLoading && (
            <i className="center-icon text-background fa fa-spinner fa-spin"></i>
          )}
          Upload Excel To Convert
        </button>
      </div>
    </div>
  );
}

export default App;
